import React from 'react';

import Layout from '../components/Layout';

//const IndexPage = () => (
 // <Layout fullMenu>
const IndexPage = () => <Layout fullMenu>
    <article id="main">
      <header>
        <h2>McMaster Notebank</h2>
        {/*<p>Aliquam ut ex ut interdum donec amet imperdiet eleifend</p>*/}
      </header>
      <section className="wrapper style5">
        <div className="inner">
          <p>
            I have accumulated some of the class notes that I've taken while a student at McMaster and have made them available to view for other McMaster students.
            Below are links to some OneNote notebooks for first year engineering and mechatronics classes.
          </p>

          <ul>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/EpVOuFBH58pBjt0lsUO7lPsBF4jSFpZK0KkSesCuib1Jqw?e=VeOlFr">Math 2ZZ3: Engineering Mathematics IV</a></li>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/EspWVTP47HpHqgmy9B72w7kBfpHYmP9G3fkumjrferNVNw?e=UcwCiY">Stats 3Y03: Probability and Statistics for Engineering</a></li>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/EqXMwqvfoshHs1kIMYvgc9ABm2Fgkq_PkTOWys7Zh5cNDw?e=yBupjN">Engineer 2P04: Engineering Mechanics</a></li>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/Eob4GWzcdDVFkzNpCaac1xEBbeF0UBjzfaRraoN0WiBJMQ?e=Q5VqYA">MechEng 2QA4/2Q04: Engineering Mechanics: Kinetics and Dynamics</a></li>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/EpWVgt14oI5Osx7x8Jt-XRABEmwCmcQ8n8cjh5eHAzXdDQ?e=D1RMMy">SfwrEng/Mechtron 3MX3: Signals and Systems</a></li>
            <li><a href="https://mcmasteru365-my.sharepoint.com/:o:/g/personal/yangl56_mcmaster_ca/EsBugBIcLuFElDzGDtwc2oYBLELC_CEATvgghE7LCGjw_Q?e=kdvcrm">EngPhys 2A04: Electricity and Magnetism</a></li>
          </ul>

        </div>
      </section>
    </article>
  </Layout>
//);

export default IndexPage;
